import React from "react"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"

import HomeContacto from "../components/home-contacto"
import Hammer from "../images/hammer.jpg"

const IndexPage = () => (
  <Layout>
    <div className="w-full lg:w-10/12 mx-auto">
      <Navigation2 />

      <div className="w-full lg:flex mx-auto">
        <div className="hidden lg:block w-full lg:w-1/3 py-3 px-5 ">
          <img
            src={Hammer}
            alt="Derecho"
            className=" w-full object-cover lg:p-5 lg:pt-0"
          />
        </div>
        <div className="w-full lg:w-2/3 px-5 lg:px-0 mt-10 lg:mt-0">
          <h1 className="text-3xl">Derecho Constitucional</h1>

          <p className="copy">
            Algunos de nuestros servicios relacionados al derecho constitucional
            son:
          </p>

          <ul className="lists lg:ml-0">
            <li>Acciones de inconstitucionalidad</li>
            <li>Recursos de amparo</li>
            <li>Recursos de Habeas Corpus</li>
            <li>Procesos ante la Corte Interamericana de Derecho</li>
            <li>Opiniones jurí­dicas</li>
          </ul>
        </div>
      </div>
    </div>

    <HomeContacto />
  </Layout>
)

export default IndexPage
